import {FpAxios} from 'fp-components';
import {BranchFilter, FiltersResponse } from '@/models/Filter';
import BranchListElement, {
  Branch,
  BranchLandsDto,
  BranchListElementDto,
  CreateBranchModel
} from '@/modules/branches/models/Branch';
import {UserBusinessHoursElement} from '@/models/UserBusinessHoursElement';
import {omit} from 'lodash-es';
import {BranchType} from '@/modules/branches/enums/BranchType';

class BranchApi {
  private branchAxios = FpAxios.create();
  readonly baseUrl = 'branches';

  async filterBranches(filters?: BranchFilter): Promise<FiltersResponse<BranchListElement>> {
    const response = await this.branchAxios
      .post<FiltersResponse<BranchListElement>>(`${this.baseUrl}/search`, filters ? filters.apiData : {});
    response.data.items = response.data.items.map((dto: BranchListElementDto) => new BranchListElement(dto));
    return response.data;
  }

  async getOwnBranches(): Promise<BranchListElementDto[]> {
    const response = await this.branchAxios
      .get(`${this.baseUrl}/find/own`);
    return response.data;
  }

  async getBranch(id: number): Promise<Branch> {
    const response = await this.branchAxios.get(`${this.baseUrl}/${id}`);
    return new Branch(response.data);
  }

  async deleteBranch(id: number) {
    const response = await this.branchAxios.delete(`${this.baseUrl}/${id}`);
    return response;
  }

  async updateBranch(id: number,branch: Branch): Promise<Branch> {
    const response = await this.branchAxios.put(`${this.baseUrl}/${id}`, omit<Branch>(branch, 'agents'));
    return new Branch(response.data);
  }

  async createBranch(branch: CreateBranchModel): Promise<Branch> {
    const response = await this.branchAxios.post(`${this.baseUrl}`,branch);
    return new Branch(response.data);
  }

  async updateBranchHours(id: number,hours: UserBusinessHoursElement[]): Promise<Array<UserBusinessHoursElement>> {
    const response = await this.branchAxios.put(`${this.baseUrl}/${id}/hours`,hours);
    return response.data;
  }

  async getBranchLands(id: number): Promise<BranchLandsDto> {
    const response = await this.branchAxios.get(`${this.baseUrl}/${id}/location/range`);
    return response.data;
  }

  async updateBranchLands(id: number, landsData: BranchLandsDto) {
    const response = await this.branchAxios.put(`${this.baseUrl}/${id}/location/range`,landsData);
    return response.data;
  }

  async getLeadsBranchesTypes(): Promise<BranchType[]> {
    const response = await this.branchAxios.get(`${this.baseUrl}/leads/branches-types`);
    return response.data;
  }

  async updateLeadsBranchesTypes(branchesTypes: BranchType[]) {
    const response = await this.branchAxios.put(`${this.baseUrl}/leads/branches-types`,branchesTypes);
    return response.data;
  }
}

export default new BranchApi();
