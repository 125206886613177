var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fp-field',{ref:"radio",attrs:{"keys":_vm.$props}},[(_vm.label)?_c('p',{staticClass:"radio-group-label"},[_vm._v(_vm._s(_vm.label)+"\n    "),(_vm.tooltip)?_c('span',{staticStyle:{"margin-left":"4px"}},[_c('v-icon',{directives:[{name:"fp-tooltip",rawName:"v-fp-tooltip",value:(_vm.tooltip),expression:"tooltip"}],staticClass:"fw-pointer"},[_vm._v("info")])],1):_vm._e()]):_vm._e(),_vm._v(" "),_c('v-radio-group',_vm._g({staticClass:"fp-radio-group",class:{'radio-width-fix': !_vm.fillWidth, 'radio-row': _vm.row, 'mt-0 pt-0': _vm.solo},attrs:{"id":_vm.id,"prependIcon":_vm.prependIcon,"appendIcon":_vm.appendIcon,"hide-details":"auto","rules":_vm.rules,"required":_vm.required,"validate-on-blur":_vm.validateOnBlur,"column":!_vm.row,"row":_vm.row,"disabled":_vm.disabled},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},_vm.$listeners),[_vm._l((_vm.items),function(item,index){return [_c('v-row',{key:index,staticClass:"align-center mt-0",class:{
        'solo-radio': _vm.solo,
        'connected-radio': _vm.connected,
        'radio-holder': !_vm.row && !_vm.solo && !_vm.connected
      },attrs:{"no-gutters":""}},[_c('v-radio',{class:{
            'description-label': item[_vm.description],
            'clearable': _vm.clearable,
            'fill-width': _vm.fillWidth,
            'radio-focused': index === _vm.focusedItem,
            'fp-input-outline': _vm.outline,
            'box': _vm.box,
            'dense': _vm.dense,
            'fp-radio-group': true,
            'primary--text': !_vm.disabled && !item.disabled,
          },staticStyle:{"width":"100%"},attrs:{"color":"primary","disabled":item.disabled,"label":item[_vm.description] ? undefined : item[_vm.itemName],"value":_vm.returnObject ? item : item[_vm.itemKey]},on:{"click":function($event){$event.stopPropagation();}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._t("label",function(){return [_c('p',{staticClass:"d-block mb-0 text--primary"},[_vm._v("\n                "+_vm._s(item[_vm.itemName])+"\n                "),(item.tooltip)?_c('v-tooltip',_vm._b({attrs:{"max-width":"440px","top":""},scopedSlots:_vm._u([{key:"activator",fn:function({on}){return [_c('v-icon',_vm._g({staticClass:"tooltip",attrs:{"size":"18px"}},on),[_vm._v("\n                      info\n                    ")])]}}],null,true)},'v-tooltip',_vm.itemTooltipAttrs,false),[_vm._v(" "),_c('div',{attrs:{"role":"button"},on:{"click":_vm.onTooltipClick}},[_vm._v("\n                    "+_vm._s(item.tooltip)+"\n                  ")])]):_vm._e()],1),_vm._v(" "),(!_vm.solo && item[_vm.description])?_c('p',{staticClass:"mb-0"},[_c('span',{staticClass:"d-inline-block"},[_vm._v(_vm._s(item[_vm.description]))])]):_vm._e()]},{"item":item,"label":item[_vm.itemName],"description":item[_vm.description],"radioIndex":index})]},proxy:true}],null,true)}),_vm._v(" "),(_vm.clearable && item[_vm.itemKey] === _vm.model)?_c('v-btn',{key:index,staticClass:"ml-1",attrs:{"color":_vm.$vuetify.theme.currentTheme.error + '33',"depressed":"","icon":""}},[_c('v-icon',{attrs:{"color":"error"},on:{"click":function($event){return _vm.emitValue(null)}}},[_vm._v("mdi-close")])],1):_vm._e()],1)]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }