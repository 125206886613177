import {Utils} from '@/services/utils/BasicUtils';
import {AccreditationPersonDto} from '@/modules/accredition/models/backend/AccreditationPersonDto';
import {AddressDTO} from '@/modules/multiForm/shared/MultiformModels';
import {PersonalId} from '@/models/Client';

export class AccreditationPerson implements AccreditationPersonDto {
  constructor(accreditationPersonDto?: AccreditationPersonDto) {
    if (accreditationPersonDto) {
      Utils.assign(this, accreditationPersonDto);
    }
  }

  public activeCorrespondenceAddress: boolean = false;
  public firstName: Nullable<string> = null;
  public secondName: Nullable<string> = null;
  public lastName: Nullable<string> = null;
  public activeOfficialAddress: boolean = false;
  public address: AddressDTO = new AddressDTO();
  public birthName: Nullable<string> = null;
  public birthPlace: Nullable<string> = null;
  public contactPhone: Nullable<string> = null;
  public correspondenceAddress: AddressDTO = new AddressDTO();
  public email: string = '';
  public expertInvitationId?: number;
  public fatherName: Nullable<string> = null;
  public iban: Nullable<string> = null;
  public motherName: Nullable<string> = null;
  public mothersMaidenName: Nullable<string> = null;
  public nationalHealthFund: Nullable<string> = null;
  public nationality: string = '';
  public nip: Nullable<string> = null;
  public passport: string = '';
  public personalId: PersonalId = new PersonalId();
  public pesel: Nullable<string> = null;
  public privatePhone: Nullable<string> = null;
  public registeredAddress: AddressDTO = new AddressDTO();
  public taxOffice: Nullable<string> = null;
  public officeEmergencySituation: string = '';
  public officeReturnDocuments: string = '';
}
