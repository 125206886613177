import { render, staticRenderFns } from "./OnboardingContainer.vue?vue&type=template&id=85e4e4e2"
import script from "./OnboardingContainer.vue?vue&type=script&lang=ts&setup=true"
export * from "./OnboardingContainer.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./OnboardingContainer.vue?vue&type=style&index=0&id=85e4e4e2&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.4.23_babel-core@6.26.3_css-loader@6.11.0_webpack@5.89._kmu2dhqlsy77s2nof2cqzt33jm/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports