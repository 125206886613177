import { render, staticRenderFns } from "./MultiformListManager.vue?vue&type=template&id=4d6b3d9e&scoped=true"
import script from "./MultiformListManager.vue?vue&type=script&lang=ts"
export * from "./MultiformListManager.vue?vue&type=script&lang=ts"
import style0 from "./MultiformListManager.vue?vue&type=style&index=0&id=4d6b3d9e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.4.23_babel-core@6.26.3_css-loader@6.11.0_webpack@5.89._kmu2dhqlsy77s2nof2cqzt33jm/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d6b3d9e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/.pnpm/vuetify-loader@1.7.3_vue-template-compiler@2.7.14_vuetify@2.7.1_vue@2.7.14__webpack@5.89.0/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VBtn,VCard,VCardText,VCardTitle,VDivider,VExpandTransition,VFlex,VIcon,VLayout,VTabItem,VTabsItems})
