<template>
  <lendi-navigation-tile
      :title="title"
      :action="action"
      :to="to"
      :icon="routeObject.meta.icon || ''"
      :badgeText="badgeText"
      :childCondition="(child) => child.meta"
      @child-click="goToChildView($event)"
      :children="children"
      :isSameRouteAsChildCondition="isSameRouteAsChild"
      :class="$onboarding.tours.DASHBOARD.step[this.routeObject.meta.key]">
    <template #child="{child}">
      <template v-if="child.meta">
        <v-list-item-icon v-if="child.meta.icon">
          <v-badge
              class="discount-badge"
              :color="child.meta.badge?.color || 'error'"
              overlap
              :value="!!child.meta.badge?.is && !!child.meta.badge?.text"
              :content="child.meta.badge?.text">
            <v-icon
              :class="{'white--text': isSameRouteAsChild(child)}"
              class="pt-1">
              {{ child.meta.icon }}
            </v-icon>
          </v-badge>
        </v-list-item-icon>
        <v-list-item-title :class="{'white--text': isSameRouteAsChild(child)}">{{ child.meta.title }}</v-list-item-title>
      </template>
    </template>
      <template #title>
        <v-list-item-action v-if="routeObject.meta && routeObject.meta.icon">
          <v-badge
              class="discount-badge"
              :color="badgeColor"
              overlap
              :value="showBadge"
              :content="badgeText">
            <v-icon>{{ routeObject.meta.icon }}</v-icon>
          </v-badge>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ title }}</v-list-item-title>
        </v-list-item-content>
      </template>
  </lendi-navigation-tile>
</template>
<script lang="ts">
import Vue from 'vue';
import {Component, Prop, Watch} from 'vue-property-decorator';
import {Route, RouteConfig} from 'vue-router';
import LendiNavigationTile from '@/commons/LendiNavigationTile.vue';
@Component({
  components: {LendiNavigationTile,},
})
export default class ListMenu extends Vue {
  @Prop()
  readonly routeObject!: RouteConfig;

  public get showBadge() {
    return this.routeObject.meta?.badge
      ? !(this.routeObject.meta?.badge.is.hidden)
      : false;
  }

  public get title() {
    const title = this.routeObject.meta.title;
    return typeof title === 'function' ? title() : title;
  }

  public get badgeText() {
    return this.routeObject.meta?.badge && !this.routeObject.meta?.badge.is?.hidden
      ? this.routeObject.meta?.badge?.text || ''
      : '';
  }

  public get badgeColor() {
    return this.routeObject.meta?.badge && !this.routeObject.meta?.badge.is?.hidden
      ? this.routeObject.meta?.badge?.color || 'error'
      : '';
  }

  public goToChildView(childRoutes: RouteConfig) {
    if (childRoutes.name) {
      this.$router.push({name: childRoutes.name, });
    } else if (childRoutes.path) {
      window.location.href = childRoutes.path;
    }
  }

  get children() {
    return this.routeObject.children?.filter(x => x.meta) || [];
  }

  get action() {
    return this.routeObject.meta && typeof this.routeObject.meta.action === 'function'
      ? this.routeObject.meta.action
      : undefined;
  }

  get to() {
    return this.routeObject.meta && this.routeObject.meta.action ? undefined : `/app/${this.routeObject.path}`;
  }

  public isSameRouteAsChild(child: RouteConfig) {
    if (child?.name?.startsWith('productType') && (child?.name?.endsWith('-redirect') || child?.name?.endsWith('-form'))) {
      const childMatched = child.name.match(/productType[a-zA-Z]+-/);
      const routeMatched = this.$route.name?.match(/productType[a-zA-Z]+-/);
      return childMatched && routeMatched && childMatched[0] === routeMatched[0];
    } else return child.name === this.$route.name;
  }
}
</script>
