import {RoleTypes} from '@/commons/enums/RoleTypes';
import { I18NGetter } from '@/services/enumTranslator/I18NGetter';

export class Role {
  id: Nullable<RoleTypes> = RoleTypes.EXPERT;
  name: Nullable<string> = I18NGetter().useRolesDictionary.EXPERT;

  constructor(id?: Nullable<number>, name?: Nullable<string>) {
    if (id !== undefined) {
      this.id = id;
    }
    if (name !== undefined) {
      this.name = name;
    }
  }
}
