import { render, staticRenderFns } from "./BaseNavigation.vue?vue&type=template&id=5368e4b5"
import script from "./BaseNavigation.vue?vue&type=script&setup=true&lang=ts"
export * from "./BaseNavigation.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./BaseNavigation.vue?vue&type=style&index=0&id=5368e4b5&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.4.23_babel-core@6.26.3_css-loader@6.11.0_webpack@5.89._kmu2dhqlsy77s2nof2cqzt33jm/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/.pnpm/vuetify-loader@1.7.3_vue-template-compiler@2.7.14_vuetify@2.7.1_vue@2.7.14__webpack@5.89.0/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAlert,VAppBar,VBtn,VCol,VContainer,VDivider,VFlex,VHover,VIcon,VLayout,VList,VListItem,VListItemContent,VListItemTitle,VMain,VNavigationDrawer,VRow,VTooltip})
