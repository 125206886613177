import Deal, { DealDTO } from '../../../models/Deal';
import { FpAxios } from 'fp-components';
import Client from '@/models/Client';
import { ReturnReasonForm } from '@/modules/deals/DealInterfaces';
import Note, { NoteDTO } from '@/models/Note';
import { HistoryElement, HistoryElementDTO } from '@/models/HistoryElement';
import LoanApplication, { LoanApplicationDTO } from '@/models/LoanApplication';
import { Simulation } from '@/models/simulation/Simulation';
import ApplicationReport from '@/models/ApplicationReport';
import AuthService from '@/modules/user/AuthService';
import { DealsFilter, FiltersResponse, ReportFilter } from '@/models/Filter';
import Axios from 'axios';
import {
  DealAttributes,
  IDealAttributesDTO,
  RealEstateQuestionnaireAnswers,
  RealEstateQuestionnaireAnswersDto
} from '@/models/DealAttributes';
import { LossReason } from '@/types/ProspectLossReason';
import { DealStatus } from '@/modules/deals/enums/DealStatus';
import { FpMessageType } from '@/modules/deals/enums/FpMessageType';
import { Utils } from '@/services/utils/BasicUtils';
import Activity, {ActivityDTO} from '@/models/Activity';
import RelianceSteps from '@/models/RelianceSteps';
import { Prospect } from '@/models/Prospect';
import { RealEstate } from '@/models/RealEstate';
import { DealStage } from '@/modules/deals/enums/DealStage';
import { isNull, omit, pick} from 'underscore';
import {
  RaiffeisenAcceptProposalResponse,
  RaiffeisenProposalType,
  RaiffeisenResponse
} from '@/models/integrations/RaiffeisenLoanApplication';
import EnvironmentService from '@/env/EnvironmentService';
import {cloneDeep} from 'lodash-es';
import {CrmModules} from '@/types/CrmModules';
import processProxy from '@/env/processProxy';

class DealsApi {
  private dealsAxios = FpAxios.create();
  readonly baseUrl = 'deals';

  async getLoanApplicationsByDealId(id: number): Promise<Array<LoanApplication>> {
    const response = await this.dealsAxios.get<Array<LoanApplicationDTO>>(`${this.baseUrl}/${id}/applications`);
    const data = response.data
      .map((LoanApplicationDTO: LoanApplicationDTO) => new LoanApplication(LoanApplicationDTO))
      .sort((a: LoanApplication, b: LoanApplication) => (a.id - b.id));
    return [...data,];
  }

  async removeLoanApplicationsFromDeal(id: number, aId: number): Promise<void> {
    await this.dealsAxios.delete(`${this.baseUrl}/${id}/application/${aId}`);
  }

  async removeDeals(dealsIds: Array<number>) {
    await this.dealsAxios.delete(this.baseUrl, {data: dealsIds,});
  }

  private async filter<T, TFilter extends DealsFilter>(filters?: TFilter): Promise<FiltersResponse<T>> {
    const response = await this.dealsAxios.post<FiltersResponse<T>>(`${this.baseUrl}/search`, filters ? filters.apiData : {});
    return response.data;
  }

  async filterDeals(filters?: DealsFilter): Promise<FiltersResponse<Deal>> {
    const data = await this.filter<Deal, DealsFilter>(filters);
    data.items = data.items.map((dealDTO: DealDTO) => new Deal(dealDTO));
    return data;
  }

  async filterReports(filters?: ReportFilter): Promise<FiltersResponse<ApplicationReport>> {
    const data = await this.filter<ApplicationReport, ReportFilter>(filters);
    data.items = data.items.map((deal: Deal) => new ApplicationReport(deal));
    return data;
  }

  async getSimulationForDeal(deal: Deal): Promise<Simulation> {
    const axiosInstance = Axios.create({
      headers: {
        Authorization: `Bearer ${AuthService.token}`,
        ...(!EnvironmentService.Environment.isDev && { 'X-App-Hash': processProxy.VUE_APP_COMMIT_HASH, }),
      },
    },);
    const response = await axiosInstance.get<Simulation>(`${processProxy.VUE_APP_SIMULATION_URL}${deal.simulationPrefix}/api/simulation/${deal.simulationId}`);
    return new Simulation(response.data);
  }

  async getDeal(id: number): Promise<Deal> {
    const response = await this.dealsAxios.get<DealDTO>(`${this.baseUrl}/${String(id)}`);
    return new Deal(response.data);
  }

  async getDeals(): Promise<Array<Deal>> {
    const response = await this.dealsAxios.get<Array<DealDTO>>(`${this.baseUrl}/my`);
    return response.data.map((dealDTO: DealDTO) => new Deal(dealDTO));
  }

  async updateRealestatesMultiform(id: number, realestates: Array<RealEstate>): Promise<void> {
    await this.dealsAxios.put(`${this.baseUrl}/${id}/real-estate-details`, realestates);
  }

  async removeDeal(id: number): Promise<void> {
    await this.dealsAxios.delete(`${this.baseUrl}/${id}`);
  }

  async addDeal(deal: Deal): Promise<Deal> {
    const response = await this.dealsAxios.post(this.baseUrl, deal);
    const newDeal = new Deal(response.data);
    newDeal.agent = deal.agent;
    return newDeal;
  }

  async getDealActivities(id: number) {
    const response = await this.dealsAxios.get(`${this.baseUrl}/${id}/activities`);
    return response.data.map((activityDto: ActivityDTO) => new Activity(activityDto));
  }

  async createDealWithActivity(deal: Deal, activity: Activity, client?: Client | null, attributes?: Partial<DealAttributes>): Promise<{deal: Deal, activity: Activity, client: Client}> {
    if (activity.client) {
      activity.clientId = activity.client.id;
      deal.clientIds = [activity.clientId,];
    }

    const data: { deal: Deal, activity: Activity, client?: Client | null, attributes?: Partial<DealAttributes> } = {deal, activity, client, attributes};
    const response = await this.dealsAxios.post(`${this.baseUrl}/create-with-activity`, data);
    return {deal: new Deal(response.data.deal), activity: new Activity(response.data.activity), client: new Client(response.data.client)};
  }

  async addClients(dealId: number, clients: Array<Client>): Promise<Deal> {
    const response = await this.dealsAxios.post(`${this.baseUrl}/${dealId}/clients`, {clientIds: clients.map((c: Client) => c.id),});
    return new Deal(response.data);
  }

  async removeClient(dealId: number, clientId: number): Promise<Deal> {
    const response = await this.dealsAxios.delete(`${this.baseUrl}/${dealId}/clients/${clientId}`);
    return new Deal(response.data);
  }

  async removeAnonymousBorrower(dealId: number, borrowerId: number): Promise<Deal> {
    const response = await this.dealsAxios.delete(`${this.baseUrl}/${dealId}/anonymous-borrowers/${borrowerId}`);
    return new Deal(response.data);
  }

  async editDeal(deal: Deal): Promise<Deal> {
    const response = await this.dealsAxios.put(`${this.baseUrl}/${deal.id}`, {deal,});
    return response.data;
  }

  async returnToCC(dealId: number, reasonForm: ReturnReasonForm): Promise<Deal> {
    const response = await this.dealsAxios.post(`${this.baseUrl}/${dealId}/return-to-agent`, reasonForm);
    return response.data;
  }

  async getNotes(dealId: number): Promise<Array<Note>> {
    const response = await this.dealsAxios.get<Array<NoteDTO>>(`${this.baseUrl}/${String(dealId)}/notes`);
    return response.data.map((noteDTO: NoteDTO) => new Note(noteDTO));
  }

  async addNote(dealId: number, note: Note): Promise<HistoryElement<Note>> {
    const newHistoryElement = new HistoryElement({
      data: note,
      type: FpMessageType.NOTE_ADDED,
      dealId,
      createdDate: (new Date()).toISOString(),
    });
    const response = await this.dealsAxios.post<NoteDTO>(`${this.baseUrl}/${dealId}/notes`, note);
    newHistoryElement.data = new Note(response.data);
    newHistoryElement.createdDate = newHistoryElement.data.createdAt;
    return newHistoryElement;
  }

  async getDealHistory(dealId: number): Promise<Array<HistoryElement<any>>> {
    const response = await this.dealsAxios.get<Array<HistoryElementDTO<any>>>(`${this.baseUrl}/${dealId}/history`);
    return response.data.map((historyElementDTO: HistoryElementDTO<any>) => new HistoryElement(historyElementDTO));
  }

  async setNewStep(dealId: number, newStep: string): Promise<boolean> {
    const response = await this.dealsAxios.put<boolean>(`${this.baseUrl}/${dealId}/wizard-step/${newStep}`);
    return response.data;
  }

  async getDealsByClientId(clientId: number, page: Nullable<Number> = null, rowsPerPage: Nullable<Number> = null): Promise<Array<Deal>> {
    let url = '';
    if (page !== null && rowsPerPage !== null) {
      url = `${this.baseUrl}/clients/${clientId}?page=${page}&rows_per_page=${rowsPerPage}`;
    } else {
      url = `${this.baseUrl}/clients/${clientId}`;
    }
    const response = await this.dealsAxios.get<Array<DealDTO>>(url);
    return response.data.map((dealDTO: DealDTO) => new Deal(dealDTO));
  }

  async getClientLast5PendingDeals(clientId: number): Promise<FiltersResponse<Deal>> {
    const filters = new DealsFilter({
      rowsPerPage: 5,
      stages: [DealStage.PENDING,],
      clients: [clientId,],
    });
    const data = await this.filter<Deal, DealsFilter>(filters);
    data.items = data.items.map((dealDTO: DealDTO) => new Deal(dealDTO));
    return data;
  }

  public async downloadAllZippedFiles(dealId: number, filled = false, applicationId?: number): Promise<string> {
    const response = await this.dealsAxios.get<string>(`${this.baseUrl}/${dealId}/documents/all-attachments`, {
      params: {
        fill: EnvironmentService.Environment.hasAccessToModule(CrmModules.MULTIFORM) ? filled : false,
        applicationId,
      },
    });
    return response.data;
  }

  public async settleApplication(data: PartialDeep<ApplicationReport>): Promise<Deal> {
    delete data.productVariantInstance;
    delete data.productInstance;
    const requestData = this.filterSettleRequestData(data);
    const response = await this.dealsAxios.post('settle', requestData);
    return new Deal(response.data);
  }

  public async settleApplicationWithDocuments(report: FormData): Promise<Deal> {
    const json = this.filterSettleRequestData(JSON.parse(<string>report.get('json')));
    report.set('json', JSON.stringify(json));
    const response = await this.dealsAxios.post('settle-with-files', report);
    return new Deal(response.data);
  }

  private filterSettleRequestData(data: any) {
    const filtered = pick(data, [
      'bankApplicationNumber',
      'bankContractNumber',
      'dealId',
      'applicationId',
      'clientsIds',
      'productType',
      'productName',
      'productId',
      'productVariantId',
      'productVariantName',
      'productVariantCalculationStrategy',
      'amount',
      'bankId',
      'bankSymbol',
      'bankBranchId',
      'bankConsultant',
      'bankConsultantEmail',
      'bankMargin',
      'bankCommission',
      'consultantCommission',
      'consultantCommissionReward',
      'submittedAt',
      'realisationAt',
      'duration',
      'partnerId',
      'partnerSourceId',
      'partnerSalesmanId',
      'note',
      'brokerOrganisationId',
      'attachments',
      'insurance',
      'clientAgreedCreditDraft',
      'clientAgreedCreditBefore21Days',
      'lcLifeInsurancePolicyNumber',
      'interestRate',
      'loanPurposeActivity',
      'loanPurposeWhat',
    ] as Array<keyof ApplicationReport>);
    return omit(filtered, isNull);
  }

  public async delegateDeal(formData: FormData) {
    const response = await this.dealsAxios.post('delegate', formData);
    return response.data;
  }

  public async delegateDealToDifferentOrganisation(formData: FormData): Promise<Deal> {
    const response = await this.dealsAxios.post('transfer', formData);
    return new Deal(response.data);
  }

  public async updateDeal(deal: Partial<Deal>): Promise<Deal> {
    const data: Partial<Deal> = Utils.omit<Partial<Deal>>(deal,
      'households',
      'realEstateDetails',
      'documentTags',
      'clients',
      'settledDocuments',
      'history',
      'attributes',
      'anonymousBorrowers',
      'clientIds',
    );
    const response = await this.dealsAxios.put<DealDTO>(`${this.baseUrl}/${deal.id}`, data);
    const clonedDeal = cloneDeep(deal);
    return new Deal(Utils.assign(clonedDeal, response.data));
  }

  public async closeDeal(dealId: number, lossReason: LossReason, activityId?: number | undefined | null) {
    const data = {
      id: lossReason.lossReason,
      name: lossReason.lossReasonName,
      description: lossReason.lossReasonDescription,
      activityId: activityId,
    };
    const response = await this.dealsAxios.put(`${this.baseUrl}/${dealId}/close`, data);
    return response.data;
  }

  public async settleProvision(settleData = {}) {
    const response = await this.dealsAxios.post('settle-provision', settleData);
    return response.data;
  }

  public async setVerifier(deal: Deal) {
    await this.dealsAxios.put(`${this.baseUrl}/${deal.id}/verifier`);
    const response = await this.getDeal(deal.id);
    return response;
  }

  public async unsetVerifier(deal: Deal) {
    await this.dealsAxios.delete(`${this.baseUrl}/${deal.id}/verifier`);
    const response = await this.getDeal(deal.id);
    return response;
  }

  public async updateDealStatus(dealId: number, status: DealStatus) {
    const response = await this.dealsAxios.put(`${this.baseUrl}/${dealId}/status`, {value: status,});
    return response;
  }

  public async addAttributes(dealId: number, dealAttributes: Partial<DealAttributes>) {
    dealAttributes.dealId = dealId;
    const response = await this.dealsAxios.post(`${this.baseUrl}/${dealId}/attributes`, dealAttributes);
    return response.data;
  }

  public async setAttributes(dealId: number, meetingSummary: Partial<DealAttributes>) {
    meetingSummary.dealId = dealId;
    const response = await this.dealsAxios.put(`${this.baseUrl}/${dealId}/attributes`, meetingSummary);
    return response.data;
  }

  public async getAttributes(dealId: number): Promise<DealAttributes> {
    const response = await this.dealsAxios.get<IDealAttributesDTO>(`${this.baseUrl}/${dealId}/attributes`);
    return new DealAttributes(response.data);
  }

  async changeEditableByClientFlag(dealId: number, editable: boolean): Promise<void> {
    await this.dealsAxios.put(`${this.baseUrl}/${dealId}/is-editable-by-client`, editable);
  }

  async setJobForExcelReport(filters: DealsFilter): Promise<string> {
    const response = await this.dealsAxios.post<{ message: string }>(`${this.baseUrl}/search?xls=true`,
      filters ? filters.apiData : {});
    return response.data.message;
  }

  async getExcelReport(fileId: string): Promise<Blob> {
    const response = await this.dealsAxios.get(`files/${fileId}`, {
      responseType: 'blob',
    });
    return new Blob([response.data,], {type: 'application/excel',});
  }

  async getZippedDocument(fileId: string): Promise<Blob> {
    const response = await this.dealsAxios.get(`files/${fileId}`, {
      responseType: 'blob',
    });
    return new Blob([response.data,], {type: 'application/zip',});
  }

  async updateRelianceSteps(dealId: number, relianceSteps: RelianceSteps): Promise<RelianceSteps> {
    const response = await this.dealsAxios.post(`${this.baseUrl}/${dealId}/reliance-steps`, relianceSteps);
    return new RelianceSteps(response.data);
  }

  async getRelianceSteps(dealId: number): Promise<RelianceSteps> {
    const response = await this.dealsAxios.get(`${this.baseUrl}/${dealId}/reliance-steps`);
    return new RelianceSteps(response.data);
  }

  async sendRealEstateQuestionnaireProspectByDealId(
    dealId: number,
    data: RealEstateQuestionnaireAnswers): Promise<void> {
    await this.dealsAxios.post(`${this.baseUrl}/${dealId}/questionnaire/real-estate`, data);
  }

  async sendRealEstateQuestionnaireProspect(prospect: Prospect): Promise<RealEstateQuestionnaireAnswers> {
    const data: any = prospect.realEstateQuestionnaire;
    data.clientPhone = prospect.contact.phone;
    data.urls = prospect.realEstateQuestionnaire.urls.filter(x => x);
    const response = await this.dealsAxios.post<RealEstateQuestionnaireAnswersDto>(`${this.baseUrl}/client-phone/${prospect.contact.phone}/questionnaire/real-estate`, data);
    return new RealEstateQuestionnaireAnswers(response.data);
  }

  async getRealEstateQuestionnaireByPhoneNumber(phone: string): Promise<Nullable<RealEstateQuestionnaireAnswers>> {
    const response = await this.dealsAxios.get<Nullable<RealEstateQuestionnaireAnswersDto>>(
      `${this.baseUrl}/client-phone/${phone}/questionnaire/real-estate`);
    return response.data ? new RealEstateQuestionnaireAnswers(response.data) : null;
  }

  async getRealEstateQuestionnaireByDealId(dealId: number): Promise<Nullable<RealEstateQuestionnaireAnswers>> {
    const response = await this.dealsAxios.get<Nullable<RealEstateQuestionnaireAnswers>>(
      `${this.baseUrl}/${dealId}/questionnaire/real-estate`);
    return response.data;
  }

  async returnDealToAgent(dealId: number, returnReasonForm: ReturnReasonForm,
    activityId?: number | undefined | null): Promise<any> {
    const data = {
      reason: returnReasonForm.reason,
      description: returnReasonForm.description,
      activityId: activityId,
    };
    const response = await this.dealsAxios.post<ReturnReasonForm>(
      `${this.baseUrl}/${dealId}/return-to-agent`, data);
    return response.data;
  }

  async createRaiffeisenLead(dealId: number,clientId: number, applicationId: number): Promise<RaiffeisenResponse> {
    const data = {
      clientId,
      applicationId,
    };
    const response = await this.dealsAxios.post(`${this.baseUrl}/${dealId}/raiffeisen/create/lead`,data,);
    return new RaiffeisenResponse(response.data);
  }

  async acceptRaiffeisenProposal(
    dealId: number,
    clientId: number,
    applicationId: number,
    leadId: string,
    proposalType: RaiffeisenProposalType
  ): Promise<RaiffeisenAcceptProposalResponse> {
    const data = {
      clientId,
      applicationId,
      leadId,
      proposalType,
    };
    const response = await this.dealsAxios.post(`${this.baseUrl}/${dealId}/raiffeisen/approve/lead`, data);
    return new RaiffeisenAcceptProposalResponse(response.data);
  }

  async sendSimulationPdf(dealId: number, userId: number, url: string, filename: string) {
    const response = await this.dealsAxios.post(`${this.baseUrl}/${dealId}/sendSimulationsPdf`, {
      userId,
      url,
      filename,
    });
    return response.data;
  }
}

export default new DealsApi();
