import { render, staticRenderFns } from "./MortgageSimulationForm.vue?vue&type=template&id=5c2540ac&scoped=true"
import script from "./MortgageSimulationForm.vue?vue&type=script&lang=ts"
export * from "./MortgageSimulationForm.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.4.23_babel-core@6.26.3_css-loader@6.11.0_webpack@5.89._kmu2dhqlsy77s2nof2cqzt33jm/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c2540ac",
  null
  
)

export default component.exports