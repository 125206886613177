import './windowConfig';
import 'jquery/dist/jquery.min';
import 'uno.css';
import Vue from 'vue';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import '@mdi/font/css/materialdesignicons.css';
import FpServices, {FpAxios, FpVuetify} from 'fp-components';
import App from './App.vue';
import 'fp-components/dist/FpComponents.css';
import router from './router';
import store from './store/store';
import EnvironmentService from '@/env/EnvironmentService';
import AuthService from '@/modules/user/AuthService';
import FpFilters from '@/filters/FpFilters';
import moment from 'moment';
import 'moment/locale/pl';
import 'moment/locale/ro';
import {Utils} from '@/services/utils/BasicUtils';
import pl from 'vuetify/src/locale/pl';
import en from 'vuetify/src/locale/en';
import ro from 'vuetify/src/locale/ro';
import uk from 'vuetify/src/locale/uk';
import de from 'vuetify/src/locale/de';

import GoToService from './router/GoToService';
import CommonComponents from '@/components/commons/CommonComponents';
import CypressUtils from '@/env/cypress/CypressUtils';
import {AxiosError, AxiosResponse} from 'axios';
import UserCom from '@/services/log/UserCom';
import TagService from '@/services/log/TagService';
import Logger from '@/services/log/Logger';
import {TokenType, TOKEN_TYPE_KEY} from '@/commons/enums/TokenType';
import ClientAuthService from '@/modules/client/services/ClientAuthService';
import BadgesPlugin from '@/components/commons/Badges';
import NotificationService from '@/modules/notification/NotificationService';
import {
  I18NGetter
} from '@/services/enumTranslator/I18NGetter';
import EnvironmentTheme from '@/env/services/EnvironmentTheme';
import { createPinia, PiniaVuePlugin } from 'pinia';
import {VuetifyLocale} from 'vuetify/types/services/lang';
import {I18N} from '@/services/enumTranslator/I18NDictionary';
import {Onboarding} from '@/plugins/onboarding';
import {SystemLanguage} from '@/services/enumTranslator/SystemLanguage';
import {i18nFormatter} from '@/services/StringFormatter';
import resetStore from '@/commons/services/store/ResetStore';
import {isRefValid} from '@/services/utils/isRefValid';
// @ts-ignore
import VHtmlSafe from '@/directives/commons/VHtmlSafe';
import ModalService from '@/components/fpComponents/services/ModalService/ModalService';
import SnackbarService from '@/components/fpComponents/SnackbarService';
import processProxy from '@/env/processProxy';

const lineClamp = require('vue-line-clamp');

moment.locale(I18N.systemLanguage.toLowerCase());

TagService.connect();
UserCom.connect();
Logger.connect();

Vue.prototype.errorHandler = function(err: AxiosError, message?: string): void {
  console.error(err);
  let msg = err.response?.data?.message || message;
  const description = err.response?.data?.description;
  if (description) {
    msg = msg.concat(' ' + description);
  }
  if (msg) {
    Vue.prototype.$snackbarService.openErrorSnackbar(msg);
  }
};

Vue.config.productionTip = false;
Vue.prototype.$cy = CypressUtils.ids;
Vue.prototype.$env = EnvironmentService.Environment;
Vue.prototype.$utils = Utils;
Vue.prototype.$goto = GoToService;

// TODO replace with a object instead of next params
Vue.prototype.$validate = function(key: string, scrollContainer?: HTMLElement, validateFormValue?: boolean): boolean {
  const ref: any = this.$refs[key];
  return isRefValid(ref, key, scrollContainer, !!validateFormValue);
};

Object.defineProperty(Vue.prototype, '$user', {
  get() {
    return AuthService.User;
  },
});

Object.defineProperty(Vue.prototype, '$i18nFormatter', {
  get() {
    return i18nFormatter;
  },
}
);
FpAxios.BaseUrl = processProxy.VUE_APP_BASE_URL;
FpAxios.tokenGetter = () => AuthService.token;

FpAxios.onNotFound = (error: AxiosError) => Logger.endpointErrorHandler(error);
FpAxios.onBadRequest = (error: AxiosError) => Logger.endpointErrorHandler(error);
FpAxios.onUnauthorized = (error: AxiosError) => {
  Logger.endpointErrorHandler(error);
  if (localStorage.getItem(TOKEN_TYPE_KEY) === TokenType.CLIENT) {
    ClientAuthService.logout();
  } else {
    AuthService.logout();
  }
};

Object.defineProperty(Vue.prototype, '$i18n', {
  get() {
    return I18NGetter();
  },
});
FpAxios.onForbidden = (error: AxiosError) => {
  Logger.endpointErrorHandler(error);
  if (localStorage.getItem(TOKEN_TYPE_KEY) === TokenType.CLIENT) {
    ClientAuthService.logout();
  } else if (error.response?.data.key === 'forbidden_hr_locked') {
    Vue.prototype.$snackbarService.openErrorSnackbar(I18NGetter().useMain.ERROR_HR_LOCKED);
    GoToService.hrLocked();
  } else {
    Vue.prototype.$snackbarService.openErrorSnackbar(I18NGetter().useMain.ERROR_NO_ACCESS);
    GoToService.index();
  }
};

FpAxios.revokeToken = (r: AxiosResponse) => AuthService.revokeToken(r);

FpAxios.defaultHeaders = {};
FpAxios.defaultHeaders[processProxy.VUE_APP_HEADER_NAME_COMMIT_HASH!] = processProxy.VUE_APP_COMMIT_HASH!;
Vue.use(lineClamp);
Vue.use(BadgesPlugin);
Vue.use(PiniaVuePlugin);
Vue.use(FpFilters);
// @ts-ignore
Vue.use(FpVuetify, {
  exclude: ['fp-btn', 'fp-field', 'fp-input', 'fp-international-input', 'fp-select', 'loading-button', 'fp-radio-group',],
});
const appDomainConfig = EnvironmentService.Environment.getAppDomainConfig();
// @ts-ignore
Vue.use(FpServices, {
  materialDesignVersion: 2,
  dateFormat: 'DATE_OBJECT',
  personalIdentificationNumberType: appDomainConfig.personalIdNumber.type,
  phoneDefault: appDomainConfig.region,
  systemLanguage: I18N.systemLanguage,
  disableFilters: true,
  fpRadioConfig: {
    style: 0,
  },
  notificationObservable: NotificationService.Observable,
});
Vue.use(Onboarding);
Vue.use(CommonComponents);
Vue.use(VHtmlSafe);
Vue.prototype.$modalService = ModalService;
Vue.prototype.$snackbarService = SnackbarService;

const pinia = createPinia();

const vuetifyLocales: Record<Lowercase<SystemLanguage>, VuetifyLocale> = {
  pl,
  en,
  ro,
  uk,
  de,
};

const vuetify = FpVuetify.vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    dark: false,
    themes: EnvironmentTheme.LENDI,
  },
  lang: {
    locales: vuetifyLocales,
    current: I18N.systemLanguage.toLowerCase(),
  },
});
pinia.use(resetStore);
new Vue({
  router,
  store,
  // @ts-ignore
  vuetify,
  pinia,
  render: h => h(App),
}).$mount('#app');
