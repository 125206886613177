<template>
  <v-card :id="$cy.dealEditModal">
    <v-card-title>
      {{ I18NGetter().useDealBadge.EDIT_DEAL }}
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="pt-2">
      <v-form :ref="EDIT_DEAL_FORM_KEY">
        <v-container pa-0 grid-list-lg>
          <v-layout wrap>
            <fp-input prepend-icon="edit"
                      xs12
                      v-model.trim="deal.name"
                      required
                      :minLength="3"
                      :id="$cy.dealName"
                      :label="I18NGetter().useEditDeal.DEAL_NAME"/>
            <fp-input
              xs12
              prepend-icon="none"
              hide-details
              md9
              :id="$cy.dealEditLoanAmount"
              :suffix="$env.currentCurrency()"
              type="number"
              :label="deal.isCreditDeal ? I18NGetter().useEditDeal.APPLIED_CREDIT_AMOUNT : I18NGetter().useEditDeal.TRANSACTION_VALUE"
              v-model="deal.loanAmount"/>
            <v-flex>
              <v-row no-gutters>
                <v-col class="shrink align-self-center">
                  <v-icon class="mr-2">mdi-calendar</v-icon>
                </v-col>
                <fp-month-picker class="pa-0" block v-model="deal.expectedRealisationMonth">
                  <template #label>
                    {{ I18NGetter().useEditDeal.PLANNED_MONTH }} {{ deal.isCreditDeal ? I18NGetter().useEditDeal.START : I18NGetter().useEditDeal.PRE_START }}:
                    {{deal.expectedRealisationAt | monthYearFormat | capitalize}}
                  </template>
                </fp-month-picker>
              </v-row>
            </v-flex>
            <fp-datepicker
              xs12
              md9
              prepend-icon="none"
              hide-details
              placeholder="##-##-####"
              :label="deal.isCreditDeal ? I18NGetter().useEditDeal.APPLYING_DATE : I18NGetter().useEditDeal.CONTRACT_SIGN_DATE"
              v-model="deal.submittedAtDateObject"/>
            <fp-datepicker
              v-if="ableToEditRealisationDate"
              xs12
              md9
              prepend-icon="none"
              hide-details
              placeholder="##-##-####"
              :label="I18NGetter().useEditDeal.START_DATE"
              :min-date="deal.submittedAtDateObject"
              v-model="deal.realisationAtDateObject"/>
            <fp-datepicker
              xs12
              md9
              :maxDate="(new Date())"
              prepend-icon="none"
              hide-details
              :label="I18NGetter().useEditDeal.LAST_MEETING_CLIENT"
              v-model="deal.lastContactWithClientAtDateObject"/>
            <v-flex xs12>
              <deal-from-partner-autocomplete
                v-model="deal.partner"
                :disabled="!canEditPartner"
                :return-object="true"
                @input="resetPartner"
                prepend-icon="mdi-handshake"/>
            </v-flex>
            <template v-if="deal.partner">
              <fp-select prepend-icon="mdi-briefcase"
                         v-if="sourceListForPartner && sourceListForPartner.length > 0"
                         v-model="deal.partnerSource"
                         :items="sourceListForPartner"
                         item-key="id"
                         :return-object="true"
                         xs12
                         item-name="name"
                         :label="sourceLabel"
                         :disabled="!canEditSource"/>
              <fp-select prepend-icon="person"
                         v-if="salesmanListForPartner && salesmanListForPartner.length > 0"
                         v-model="deal.partnerSalesman"
                         :return-object="true"
                         :items="salesmanListForPartner"
                         item-key="id"
                         xs12
                         item-name="displayName"
                         :label="I18NGetter().useEditDeal.SALESMAN"/>
            </template>
            <div class="ml-33px" v-if="bankNeedClientAgreedCreditDraft">
              <fp-radio-group
                label="Czy klient wyraził zgodę na przekazanie mu wzoru umowy kredytowej?"
                v-model="deal.clientAgreedCreditDraft"
              />
              <fp-radio-group
                :label="`Czy klient wyraził zgodę na przekazanie mu decyzji kredytowej przed upływem 21 dni od dnia złożenia kompletnego wniosku w banku?`"
                v-model="deal.clientAgreedCreditBefore21Days"
              />
            </div>
            <fp-radio-group
              v-if="deal.canChangeIsLookingForMortgage"
              :id="$cy.dealIsLookingForMortgage"
              class="ml-8 mt-4"
              v-model="deal.isLookingForMortgage"
              :label="I18NGetter().useEditDeal.IS_LOOKING_FOR_MORTGAGE"/>
          </v-layout>
        </v-container>
      </v-form>
    </v-card-text>
    <v-divider/>
    <v-card-actions>
      <loading-button
        :id="$cy.dealEditConfirm"
        primary :click="() => updateDeal()">
        {{ I18NGetter().useEditDeal.SAVE }}
      </loading-button>
      <v-spacer/>
      <loading-button @click="closeModal()">
        {{ I18NGetter().useEditDeal.CANCEL }}
      </loading-button>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import {Component} from 'vue-property-decorator';
import Deal from '@/models/Deal';
import PlaceholderButton from '@/components/PlaceholderButton.vue';
import {VueModalComponent} from 'fp-components';
import DealsApi from '@/modules/deals/services/DealsApi';
import Partner, {FinpackSource, Salesman} from '@/models/Partner';
import DictionaryStore from '@/commons/services/store/DictionaryStore';
import {PartnerType} from '@/modules/partner/enums/PartnerType';
import DealFromPartnerAutocomplete from '@/components/inputs/DealFromPartnerAutocomplete.vue';
import {cloneDeep} from 'lodash-es';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import {I18NInterface} from '@/services/enumTranslator/I18NInterface';
import {DealStage} from '@/modules/deals/enums/DealStage';

@Component({
  components: {DealFromPartnerAutocomplete, PlaceholderButton,},
})
export default class EditDeal extends VueModalComponent<Deal> {
  public deal: Deal = this.modalData.deal;
  public readonly EDIT_DEAL_FORM_KEY: string = 'EDIT_DEAL_FORM_KEY';
  public partnersList: Array<Partner> = [];
  public I18NGetter: () => I18NInterface = I18NGetter;
  public isPartnerAlreadySet: boolean = false;
  public isSourceAlreadySetOrHidden: boolean = false;
  async mounted() {
    this.isPartnerAlreadySet = !!this.deal.partner;
    this.isSourceAlreadySetOrHidden = !!this.deal.partnerSource || !!this.deal.partner?.hidePartnerSensitiveInfo;
    await this.getPartners();
  }

  public async updateDeal(): Promise<void> {
    if (this.$validate(this.EDIT_DEAL_FORM_KEY)) {
      try {
        if (this.deal.partnerSource) {
          this.deal.partnerSourceId = this.deal.partnerSource.id;
        }
        if (this.deal.partnerSalesman) {
          this.deal.partnerSalesmanId = this.deal.partnerSalesman.id;
        }
        const data: Partial<Deal> = cloneDeep(this.deal);
        delete data.status;
        const editedDeal: Deal = await DealsApi.updateDeal(data);
        this.$snackbarService.openSuccessSnackbar(I18NGetter().useEditDeal.UPDATE_DEAL_SUCCESS);
        this.closeModal(editedDeal);
      } catch (e) {
        this.errorHandler(e, I18NGetter().useEditDeal.UPDATE_DEAL_ERROR);
      }
    }
  }

  public get ableToEditRealisationDate(): boolean {
    return this.$user.isAdminOrVerifier && this.deal.stage === DealStage.WON;
  }

  public get bankNeedClientAgreedCreditDraft(): boolean {
    return this.deal.isMortgage && this.$env.isAppDomain().LENDI_PL;
  }

  public async getPartners() {
    this.partnersList = await DictionaryStore.getDictionary('partnerDictionary');
  }

  public get sourceListForPartner(): Nullable<Array<FinpackSource>> {
    return this.deal.partner?.id && this.partnersList.find(x => x.id === this.deal.partner?.id)
      ? this.partnersList.find(x => x.id === this.deal.partner?.id)!.sources
      : null;
  }

  public get salesmanListForPartner(): Nullable<Array<Salesman>> {
    return this.deal.partner?.id && this.partnersList.find(x => x.id === this.deal.partner?.id)
      ? this.partnersList.find(x => x.id === this.deal.partner?.id)!.salesman
      : null;
  }

  public get sourceLabel(): string {
    const chosenPartner = this.partnersList.find(x => x.id === this.deal.partnerId);
    return chosenPartner!.type === PartnerType.INTERNET_WEBSITE
      ? I18NGetter().useEditDeal.WHAT_PAGE : I18NGetter().useEditDeal.WHAT_INVESTMENT;
  }

  public resetPartner() {
    this.deal.partnerId = this.deal.partner ? this.deal.partner.id : undefined;
  }

  public get canEditPartner(): boolean {
    return this.$user.isAdmin || this.$user.isVerifier || !this.isPartnerAlreadySet;
  }

  public get canEditSource(): boolean {
    return this.$user.isAdmin || this.$user.isVerifier || !this.isSourceAlreadySetOrHidden;
  }
}
</script>
<style scoped lang="scss">
</style>
