import { FpAxios } from 'fp-components';
import { IApi } from '@/models/interfaces/IApi';
import {
  AccreditationRepresentant,
  AccreditationRepresentantDto
} from '@/modules/accredition/models/backend/AccreditationRepresentantDto';
import { AccreditationCompanyRequest } from '@/modules/accredition/models/backend/Requests/AccreditationCompanyRequest';
import { AuditProperties } from '@/modules/accredition/models/interfaces/AuditProperties';
import { PersonalInfo } from '@/modules/accredition/models/interfaces/PersonalInfo';
import { BirthInfo } from '@/modules/accredition/models/interfaces/BirthInfo';
import { IdentificationDocument } from '@/modules/accredition/models/interfaces/IdentificationDocument';
import { CompanyRelationship } from '@/modules/accredition/models/interfaces/CompanyRelationship';
import {
  AccreditationCompanyRequestFilesData
} from '@/modules/accredition/models/backend/Requests/AccreditationCompanyRequestFilesData';
import { AccreditationPersonDto } from '@/modules/accredition/models/backend/AccreditationPersonDto';
import { AdditionalInfoDto } from '@/modules/accredition/models/backend/AdditionalInfoDto';
import { BankAccessesDto } from '@/modules/accredition/models/backend/BankAccessesDto';
import {
  PersonalInfoConfirmationQuestions
} from '@/modules/accredition/models/interfaces/PersonalInfoConfirmationQuestions';
import { Utils, wait } from '@/services/utils/BasicUtils';
import { AccreditationInvitation } from '@/modules/accredition/models/backend/AccreditationInvitation';
import { CompanyData, FullCompanyDataDTO } from '@/models/CompanyData';
import { AccreditationStep } from '@/commons/enums/AccreditationStep';
import UserApi from '@/modules/user/services/UserApi';
import UserManagementApi from '@/modules/user/services/UserManagementApi';
import { Address } from '@/modules/multiForm/shared/MultiformModels';
import AuthService from '@/modules/user/AuthService';
import { UserBusinessHoursElement } from '@/models/UserBusinessHoursElement';
import { AxiosRequestConfig } from 'axios';
import { cloneDeep } from 'lodash-es';
import { InviteResponse } from '@/modules/accredition/services/InviteResponse';
import { MandateContractDTO } from '@/modules/accredition/models/MandateContract';
import { Person } from '@/models/Person';
import { AccreditationPerson } from '@/modules/accredition/models/AccreditationPerson';

const nameOf = <T>(name: keyof T) => name.toString();

type RepresentantReturned = AuditProperties &
  PersonalInfo &
  BirthInfo &
  IdentificationDocument &
  PersonalInfoConfirmationQuestions &
  CompanyRelationship

class AccreditationApi implements IApi {
  readonly fpAxios = FpAxios.create();
  readonly accreditationsBaseUrl = 'accreditations';
  baseUrl: string = this.accreditationsBaseUrl;

  async getHealthFunds(): Promise<{ name: string }[]> {
    type dataType = { name: string }
    const response = await this.fpAxios.get<dataType[]>('dictionaries/national-health-fund/branches');
    return response.data;
  }

  async getTaxOffices(): Promise<{ name: string }[]> {
    type dataType = { name: string }
    const response = await this.fpAxios.get<dataType[]>('dictionaries/tax-offices');
    return response.data;
  }

  async getCompany(divisionId?: number): Promise<CompanyData> {
    const config: AxiosRequestConfig | undefined = divisionId ? { params: { divisionId, }, } : undefined;
    const response = await this.fpAxios.get<CompanyData>(`${this.accreditationsBaseUrl}`, config);
    return new CompanyData(response.data);
  }

  async setStep(companyId: number, newStep: AccreditationStep): Promise<void> {
    await this.fpAxios.put(`${this.accreditationsBaseUrl}/${companyId}/step/${newStep}`);
  }

  async getStep(companyId: number): Promise<AccreditationStep> {
    const response = await this.fpAxios.get<AccreditationStep>(`${this.accreditationsBaseUrl}/${companyId}/step`);
    return response.data;
  }

  async sendInvitations(companyId: number): Promise<InviteResponse> {
    const response = await this.fpAxios.post<InviteResponse>(`${this.accreditationsBaseUrl}/${companyId}/experts/invite`);
    return response.data;
  }

  private prepareCompanyFormData(companyData: CompanyData): FormData {
    const formData = new FormData();
    const request = cloneDeep(companyData);
    if (request.secondSectionMarked) {
      delete request.knfConsumerDocuments;
    }
    if (request.firstSectionMarked || request.secondSectionMarked) {
      delete request.knfApplication;
      delete request.knfApplicationConfirmation;
      delete request.paymentConfirmation;
    }
    if (!request.krs) {
      delete request.krs;
    }
    request.hasActiveAgencyContractOrInTerminatedProcess = request.firstSectionMarked
      ? request.hasActiveAgencyContractOrInTerminatedProcess
      : false;

    formData.append(nameOf<AccreditationCompanyRequest>('data'), JSON.stringify(request));

    if (request.paymentConfirmation) {
      formData.append(nameOf<AccreditationCompanyRequestFilesData>('paymentConfirmation'),
        request.paymentConfirmation);
    }
    if (request.knfApplicationConfirmation) {
      formData.append(nameOf<AccreditationCompanyRequestFilesData>('knfApplicationConfirmation'),
        request.knfApplicationConfirmation);
    }
    if (request.knfApplication) {
      formData.append(nameOf<AccreditationCompanyRequestFilesData>('knfApplication'),
        request.knfApplication);
    }
    if (request.nonSaleDeclaration) {
      formData.append(nameOf<AccreditationCompanyRequestFilesData>('nonSaleDeclaration'),
        request.nonSaleDeclaration);
    }

    return formData;
  }

  async createCompany(companyData: CompanyData): Promise<CompanyData> {
    const formData = this.prepareCompanyFormData(companyData);
    const response = await this.fpAxios.post<CompanyData>(
      this.accreditationsBaseUrl,
      formData);

    return response.data;
  }

  async updateCompany(companyId: number, companyData: CompanyData): Promise<CompanyData> {
    const formData = this.prepareCompanyFormData(companyData);
    const response = await this.fpAxios.put<CompanyData>(
      `${this.accreditationsBaseUrl}/${companyId}/company`,
      formData);

    return response.data;
  }

  async deleteAccreditationExpert(companyId: number, expertId: number): Promise<void> {
    await this.fpAxios.delete(`${this.accreditationsBaseUrl}/${companyId}/experts/${expertId}`);
  }

  async getAccreditationExperts(companyId: number): Promise<AccreditationInvitation[]> {
    const response = await this.fpAxios.get<AccreditationInvitation[]>(`${this.accreditationsBaseUrl}/${companyId}/experts`);
    return response.data.map(dto => new AccreditationInvitation(dto));
  }

  async getAccreditationInvitation(): Promise<AccreditationInvitation> {
    const response = await this.fpAxios.get<AccreditationInvitation>(`${this.accreditationsBaseUrl}/experts/my`);
    return new AccreditationInvitation(response.data);
  }

  async getAccreditationInvitationsByUserId(userId: number): Promise<AccreditationInvitation[]> {
    const response = await this.fpAxios.get<AccreditationInvitation[]>(`${this.accreditationsBaseUrl}/experts/${userId}`);
    return response.data.map(dto => new AccreditationInvitation(dto));
  }

  async editAccreditationExpert(companyId: number, expert: AccreditationInvitation):
    Promise<WithId<AccreditationInvitation>> {
    const response = await this.fpAxios.put<AccreditationInvitation>(`${this.accreditationsBaseUrl}/${companyId}/experts/${expert.id}`, expert);
    return new AccreditationInvitation(response.data) as WithId<AccreditationInvitation>;
  }

  async addAccreditationExpert(companyId: number, expert: AccreditationInvitation):
    Promise<WithId<AccreditationInvitation>> {
    const response = await this.fpAxios.post<AccreditationInvitation>(`${this.accreditationsBaseUrl}/${companyId}/experts`, expert);
    return new AccreditationInvitation(response.data) as WithId<AccreditationInvitation>;
  }

  async deleteRepresentant(companyId: number, id: number): Promise<void> {
    await this.fpAxios.delete(`${this.accreditationsBaseUrl}/${companyId}/representants/${id}`);
  }

  async getRepresentants(companyId: number): Promise<AccreditationRepresentant[]> {
    await wait(1000);
    const response = await this.fpAxios.get<AccreditationRepresentantDto[]>(`${this.accreditationsBaseUrl}/${companyId}/representants`);
    return response.data.map(x => new AccreditationRepresentant(x));
  }

  async editRepresentant(id: number, representant: AccreditationRepresentant):
    Promise<WithId<AccreditationRepresentant>> {
    const formData = AccreditationApi.getFormDataForRepresentant(representant);
    const response = await this.fpAxios.put<AccreditationRepresentantDto>(`${this.accreditationsBaseUrl}/${id}/representants/${representant.id}`, formData);
    return new AccreditationRepresentant(response.data) as WithId<AccreditationRepresentant>;
  }

  async createRepresentant(id: number, representant: AccreditationRepresentant):
    Promise<WithId<AccreditationRepresentant>> {
    const formData = AccreditationApi.getFormDataForRepresentant(representant);
    const response = await this.fpAxios.post<AccreditationRepresentantDto>(`${this.accreditationsBaseUrl}/${id}/representants`, formData);
    return new AccreditationRepresentant(response.data) as WithId<AccreditationRepresentant>;
  }

  private static getFormDataForRepresentant(representant: AccreditationRepresentant) {
    if (!representant.activeCorrespondenceAddress) {
      representant.correspondenceAddress = representant.address;
    }
    if (!representant.activeOfficialAddress) {
      representant.registeredAddress = representant.address;
    }
    representant = Object.assign(representant, representant.personalId);
    const formData = new FormData();
    const staticData: any = Utils.assign({}, representant);
    formData.append('data', JSON.stringify(staticData));
    if (representant.attachedCertificate !== null) {
      formData.append('certificate', representant.attachedCertificate);
    }
    return formData;
  }

  async createCompanyPerson(id: number, person: AccreditationPersonDto): Promise<AuditProperties> {
    const data = await this.fpAxios.post<AuditProperties>(`${this.accreditationsBaseUrl}/${id}/persons`, person);
    return data.data;
  }

  async fetchCompanyPersons(id: number): Promise<AccreditationPerson[]> {
    const data = await this.fpAxios.get<AccreditationPersonDto[]>(`${this.accreditationsBaseUrl}/${id}/persons`);
    return data.data.map(x => new AccreditationPerson(x));
  }

  async addAccesses(accesses: BankAccessesDto): Promise<true> {
    await this.fpAxios.post(`${this.accreditationsBaseUrl}/accesses`, accesses);
    return true;
  }

  async fetchAccesses(companyId: number, userId: number): Promise<BankAccessesDto> {
    const data = await this.fpAxios.get<BankAccessesDto>(`${this.accreditationsBaseUrl}/${companyId}/accesses/${userId}`);
    return data.data;
  }

  async createAdditionalData(id: number, info: AdditionalInfoDto): Promise<true> {
    await this.fpAxios.post(`${this.accreditationsBaseUrl}/${id}/additional-data`, info);
    return true;
  }

  async fetchAdditionalData(companyId: number, invitationId: number): Promise<AdditionalInfoDto> {
    const data = await this.fpAxios.get<AdditionalInfoDto>(`${this.accreditationsBaseUrl}/${companyId}/additional-data/${invitationId}`);
    return data.data;
  }

  async saveProfileData(userAddress: Address, userBusinessHours: UserBusinessHoursElement[]): Promise<true> {
    const userId = AuthService.User!.id;
    await Promise.all([
      UserManagementApi.saveOfficeAddress(userId, userAddress),
      UserApi.editBusinessHoursForUser(userId, userBusinessHours),
    ]);
    return true;
  }

  async createMandateContractData(id: number, data: MandateContractDTO): Promise<void> {
    await this.fpAxios.post(`${this.accreditationsBaseUrl}/${id}/mandate-contract`, data);
  }

  async fetchMandateContractData(companyId: number, invitationId: number): Promise<MandateContractDTO> {
    const data = await this.fpAxios.get<MandateContractDTO>(`${this.accreditationsBaseUrl}/${companyId}/mandate-contract/${invitationId}`);
    return data.data;
  }

  async rollbackChief(companyId: number): Promise<void> {
    await this.fpAxios.put(`${this.accreditationsBaseUrl}/${companyId}/rollback-chief`);
  }

  async fetchSoleProprietorshipData(companyId: number, userId: number): Promise<CompanyData> {
    const data = await this.fpAxios.get<FullCompanyDataDTO>(`${this.accreditationsBaseUrl}/${companyId}/sole-proprietorship/${userId}`);
    return new CompanyData(data.data);
  }

  async changeCompanyOwner(companyId: number, userId: number): Promise<void> {
    await this.fpAxios.put(`${this.accreditationsBaseUrl}/${companyId}/company-details`, { userId, });
  }
}
export default new AccreditationApi();
