<template>
  <div>
    <v-row v-if="!isLead" class="py-3">
      <v-col cols="12">
        <v-layout wrap>
          <fp-datepicker
            :id="$cy.activityStartDatePicker"
            :minDate="new Date()"
            :maxDate="null"
            xs12 md6 xl6
            prepend-icon="mdi-calendar-blank-outline"
            required
            :label="$i18n.useEditActivityForm.START_DATE"
            hide-details
            :clearable="false"
            @input="onActivityStartDateChange"
            :value="activity.startDateObject"
          />
          <v-flex class="d-flex align-center" xs12 md6 xl6 v-if="!activity.isFullDay">
            <fp-timepicker
              :id="$cy.activityStartTimePicker"
              :class="{'ml-8': $vuetify.breakpoint.smAndDown, 'pr-0': showEndDate}"
              required class="timepicker-fix py-0 pl-0"
              hide-details
              :label="showEndDate ? $i18n.useEditActivityForm.START_HOUR : ''"
              :min-hour="MIN_ACTIVITY_HOUR"
              v-model="activity.startDateObject"/>
            <template v-if="!showEndDate">
              <span>-</span>
              <fp-timepicker
                required
                class="timepicker-fix py-0 pr-0"
                hide-details
                :min-hour="MIN_ACTIVITY_HOUR"
                v-model="activity.endDateObject"/>
            </template>
          </v-flex>
        </v-layout>
        <v-layout wrap v-if="showEndDate">
          <fp-datepicker
            xs12 md6 xl6
            :minDate="activity.startDateObject"
            hide-details
            @input="onActivityEndDateChange"
            required
            prepend-icon="mdi-calendar-check-outline"
            :label="$i18n.useEditActivityForm.END_DATE"
            :value="activity.endDateObject"
            :isError="isBusyScoped"
          />
          <fp-timepicker
            v-if="!activity.isFullDay"
            v-model="activity.endDateObject"
            :class="{'ml-8': $vuetify.breakpoint.smAndDown}"
            :isError="isBusyScoped"
            :label="$i18n.useEditActivityForm.END_HOUR"
            class="timepicker-fix"
            xs12 md4 xl6
            required
            hide-details
          />
        </v-layout>
        <v-layout >
          <v-flex class="d-flex flex-column flex-sm-row">
            <fp-checkbox
              class="input-spacer__checkbox mt-0 ml-8 mr-sm-4"
              v-model="showEndDate"
              :disabled="activity.isEndDateRequired"
              @input="(e) => e === false ? activity.endDateObject = null : ''"
              :label="$i18n.useEditActivityForm.END_DATE"
            />
            <fp-checkbox
              :class="{'ml-8': $vuetify.breakpoint.smAndDown}"
              class="input-spacer__checkbox mt-0"
              v-model="activity.isFullDay"
              :label="$i18n.useEditActivityForm.FULL_DAY"
            />
          </v-flex>
        </v-layout>
      </v-col>
    </v-row>
    <template v-else>
      <v-row>
        <v-col cols="8">
          <fp-select
            prepend-icon="mdi-clipboard-clock-outline"
            label="Czas trwania zadania"
            required
            item-key="value"
            v-model="activityTimeRange"
            :items="ActivityTimeRangeDictionary"
            @input="setActivityTimeRange"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <fp-datepicker
            :id="$cy.activityStartDatePicker"
            :minDate="new Date()"
            :maxDate="null"
            prepend-icon="mdi-calendar-blank-outline"
            required
            :label="$i18n.useEditActivityForm.START_DATE"
            hide-details
            @input="onActivityStartDateChange"
            :isError="isBusyScoped"
            @change="processAvailableHours"
            :value="activity.startDateObject"
          />
        </v-col>
      </v-row>
      <v-row v-if="activity.startDateObject">
        <v-col class="pl-0">
          <FpHourPicker
            v-model="activity.startDateObject"
            :id="FP_HOUR_PICKER_ID"
            :colsList="hoursOfWork"
            :loading="loading"
            :cols="$vuetify.breakpoint.xs? 3 : 4"
            required
            @bufferEnds="loadMoreHours"
            @bufferStarts="loadPreviousHours"
            @input="onDatePick"
          >
            <template #prepend="{ prevPage, page }">
              <v-btn icon>
                <v-icon :disabled="disabledPrevious(page)" @click="prevPage">
                  mdi-chevron-left
                </v-icon>
              </v-btn>
            </template>
          </FpHourPicker>
        </v-col>
      </v-row>
    </template>
  </div>
</template>
<script lang="ts" setup>
// TODO: IN GENERAL REFACTOR THIS COMPONENT IT SHOULD BE BREAK DOWN INTO SMALLER COMPONENTS MORE MANAGEABLE
import {computed, getCurrentInstance, onMounted, ref, watch} from 'vue';
import Activity, {
  DEFAULT_ACTIVITY_DURATION_BY_KIND,
  MIN_ACTIVITY_HOUR
} from '@/models/Activity';
import moment from 'moment/moment';
import {ActivityTimeRangeDictionary} from '@/commons/dictionaries/ActivityTimeRangeDictionary';
import DateUtils from '@/services/utils/DateUtils';
import UserManagementApi from '@/modules/user/services/UserManagementApi';
import {HoursOfWorkListDto} from '@/models/HoursOfWork';
import {ActivityKind} from '@/modules/activities/enums/ActivityKind';
import ScrollTo from '@/services/utils/ScrollTo';
import { useInstance } from '@/composables/useInstance';
import FpHourPicker from '@/components/fpComponents/FpHourPicker.vue';
import { useTimezone } from '@/composables/useTimezone';
import { useCalendar, DayEntry, HourEntry } from '@/composables/useCalendar';
import { useActivityHoursOfWork } from '@/composables/useActivityHoursOfWork';

const FP_HOUR_PICKER_ID = 'expert-hour-picker';

const { getActivityHoursOfWork, } = useActivityHoursOfWork();

const props = defineProps<{
  activity: Activity,
  isLead: boolean,
  isBusy: boolean,
}>();

const emit = defineEmits<{
  (e: 'isBusyChange', value: boolean): void,
}>()

const { $errorHandler, } = useInstance();
const activityTimeRange = ref<number>(DEFAULT_ACTIVITY_DURATION_BY_KIND[props.activity?.kind || ActivityKind.MEETING]);
const availableHours = ref<Nullable<HoursOfWorkListDto[]>>(null);
const loading = ref<boolean>(false);
const agentId = ref<Nullable<number>>(null);
const initialStartDate: Date | null = props.activity.startDateObject;
const initialEndDate: Date | null = props.activity.endDateObject;
const isBusyScoped = ref<Nullable<boolean>>(null);

const { uniqueDates, } = useCalendar();
const { getLocalTime, getUTCTime, } = useTimezone();

const endDateIsSameAsStartDate = computed<boolean>(() => {
  return moment(props.activity.startDateObject).isSame(props.activity.endDateObject, 'day');
});

const isEndDateVisible = computed<boolean>(() =>
  (Boolean(props.activity.endDateObject) && !endDateIsSameAsStartDate.value) ||
  props.activity.isEndDateRequired
);

const hoursOfWork = computed<Nullable<HoursOfWorkListDto[]>>(() => {
  return getActivityHoursOfWork(props.activity, availableHours.value);
});

const showEndDate = ref<boolean>(isEndDateVisible.value);

const onActivityStartDateChange = (date: Nullable<Date>) => {
  if (date && !props.activity.startDate) {
    const newDate = new Date();
    date.setHours(newDate.getHours());
  }

  if (date && (!props.activity.endDateObject || endDateIsSameAsStartDate.value)) {
    const endDate = moment(date.getTime());
    endDate.add(props.activity.defaultDuration, 'minutes');
    props.activity.endDateObject = endDate.toDate();
  }

  props.activity.startDateObject = date;
  processAvailableHours(props.isLead, agentId.value, props.activity.startDateObject);
};

const onActivityEndDateChange = (date: Nullable<Date>) => {
  if (date && !props.activity.endDate) {
    const newDate = new Date();
    date.setHours(newDate.getHours() + 1);
  }
  props.activity.endDateObject = date;
};

const setActivityTimeRange = () => {
  props.activity.endDate =
    moment(props.activity.startDate ? props.activity.startDateObject : null)
      .add((activityTimeRange.value), 'minutes').toISOString();
};

const onDatePick = () => {
  onActivityEndDateChange(null);
  setActivityTimeRange();
};

const disabledPrevious = (page: number): boolean => {
  const firstAvailableDay = moment(availableHours.value![0]!.day);
  return loading.value || (firstAvailableDay.isSame(moment(), 'day') && page === 0);
}

const computeFirstDay = (date: Date): Date => {
  const firstDay = moment(date) as moment.Moment;
  const dateSevenDaysBefore = (moment(firstDay.clone()) as moment.Moment).subtract(7, 'days');
  const currentDateTime = moment();

  return dateSevenDaysBefore.isAfter(currentDateTime) ? dateSevenDaysBefore.toDate() : currentDateTime.toDate();
};

const fetchHoursOfWork = async(startDate: Date): Promise<HoursOfWorkListDto[]> => {
  const config = {
    from: startDate,
    duration: activityTimeRange.value,
    extendBusinessHours: true,
    excludeIds : props.activity.id ? [props.activity.id!] : []
  };

  return await UserManagementApi.getHoursOfWork(agentId.value!, config);
};

const handleFetchingError = (error: any) => {
  const errorMessage = 'Wystąpił błąd podczas załadowania wcześniejszych terminów agenta';
  $errorHandler(error, errorMessage);
};

const loadPreviousHours = async(): Promise<void> => {
  try {
    loading.value = true;
    const firstDay = computeFirstDay(availableHours.value![0]!.day);
    const newHours = await fetchHoursOfWork(firstDay);

    availableHours.value = uniqueDates([...newHours, ...availableHours.value!,] as DayEntry[]);
  } catch (error) {
    handleFetchingError(error);
  } finally {
    loading.value = false;
  }
};

const loadMoreHours = async(): Promise<void> => {
  loading.value = true;
  const lastDay = getUTCTime(moment(availableHours.value![availableHours.value!.length - 1]!.day).add(1, 'day').hours(0).minutes(0), false);
  const config = {
    from: lastDay,
    duration: activityTimeRange.value,
    extendBusinessHours: true,
    excludeIds : props.activity.id ? [props.activity.id!] : []
  };
  try {
    const newHours = await UserManagementApi.getHoursOfWork(agentId.value!, config);
    availableHours.value = uniqueDates([...availableHours.value!, ...newHours,]);
  } catch (e) {
    $errorHandler(e, 'Wystąpił błąd podczas załadowania dalszych terminów agenta');
  } finally {
    loading.value = false;
  }
};

watch(() => props.activity.kind, () => {
  props.activity.isFullDay = props.activity.isVacation;
  if (props.activity.startDateObject) {
    const endDate = moment(props.activity.startDateObject.getTime());
    endDate.add(props.activity.defaultDuration, 'minutes');
    props.activity.endDateObject = endDate.toDate();
  }
  showEndDate.value = isEndDateVisible.value;
  activityTimeRange.value = DEFAULT_ACTIVITY_DURATION_BY_KIND[props.activity.kind]
});

watch(activityTimeRange, async() => {
  await processAvailableHours(props.isLead, agentId.value, props.activity.startDateObject);
});

const dateInRange = (date: Date, start: Date, end: Date): boolean => DateUtils.isDateInRange(date, start, end, false);

const isActivityDateInBusyRange = computed(() => {
  const { startDateObject, endDateObject, } = props.activity;
  return dateInRange(startDateObject!, initialStartDate!, initialEndDate!) ||
    dateInRange(endDateObject!, initialStartDate!, initialEndDate!);
});

const isBusyDateInActivityRange = computed(() => {
  const { startDateObject, endDateObject, } = props.activity;
  return dateInRange(initialStartDate!, startDateObject!, endDateObject!) ||
    dateInRange(initialEndDate!, startDateObject!, endDateObject!);
});

watch(() => [props.activity.endDateObject, props.activity.startDateObject,], () => {
  if ((isActivityDateInBusyRange.value || isBusyDateInActivityRange.value) && isBusyScoped.value === false) {
    isBusyScoped.value = true
    emit('isBusyChange', isBusyScoped.value)
  } else if ((!isActivityDateInBusyRange.value && !isBusyDateInActivityRange.value) && isBusyScoped.value) {
    isBusyScoped.value = false
    emit('isBusyChange', isBusyScoped.value);
  }
}, {deep: true,});

async function processAvailableHours(isLead, agentId, startDate) {
  if (!isLead || !agentId) {
    return;
  }

  try {
    const startDateObject = getLocalTime(startDate, false) as Date;
    let from: Date = moment().toDate();

    // is future date
    if (DateUtils.isFutureDate(startDateObject)) {
      from = moment(startDate).hours(0).toDate();
    }

    availableHours.value = await UserManagementApi.getHoursOfWork(agentId, {
      from,
      duration: activityTimeRange.value,
      extendBusinessHours: true,
      excludeIds: props.activity.id ? [props.activity.id!] : []
    });
  } catch (error) {
    console.error('Error in processAvailableHours:', error);
  }
}

function handleBusyState(isBusy) {
  if (!isBusy) {
    return;
  }

  isBusyScoped.value = isBusy;
  const startDatePicker = document.getElementById('activityStartDatePicker');
  if (startDatePicker) {
    ScrollTo.element(startDatePicker);
  } else {
    console.warn('Start date picker element not found');
  }
}

onMounted(async() => {
  agentId.value = props.activity.deal?.delegatedTo?.id || props.activity.deal?.agentId
  await processAvailableHours(props.isLead, agentId.value, props.activity.startDateObject);
  handleBusyState(props.isBusy);
});

</script>
<style lang="scss">
.timepicker-fix {
  .v-input__slot {
    height: 56px !important;
    min-height: 56px;
    max-height: 56px;
  }
}
</style>
