export enum CrmModules {
  CALENDAR = 'CALENDAR',
  MAIL = 'MAIL',
  KNOWLEDGE = 'KNOWLEDGE',
  DISCUSSIONS = 'DISCUSSIONS',
  PROSPECTS = 'PROSPECTS',
  WIDGETS = 'WIDGETS',
  LENDI = 'LENDI',
  OMNISEARCH = 'OMNISEARCH',
  LENDI_BEGININGS = 'LENDI_BEGININGS',
  LENDI_CREW = 'LENDI_CREW',
  REPORTS = 'REPORTS',
  DISCOUNTS_BASE = 'DISCOUNTS_BASE',
  EARN_WITH_LENDI = 'EARN_WITH_LENDI',
  DISCOUNTS = 'DISCOUNTS',
  MY_DIVISIONS = 'MY_DIVISIONS',
  NOTIFICATION = 'NOTIFICATION',
  CLIENT = 'CLIENT',
  CLIENTS = 'CLIENTS',
  BIK = 'BIK',
  PARTNERS = 'PARTNERS',
  ACCREDITATION_PANEL = 'ACCREDITATION_PANEL',
  USER_AVATAR = 'USER_AVATAR',
  LOAN_APPLICATIONS = 'LOAN_APPLICATIONS',
  DEALS = 'DEALS',
  DEAL_KANBAN = 'DEAL_KANBAN',
  DEAL_DETAILS = 'DEAL_DETAILS',
  LIVESPACE = 'LIVESPACE',
  USER_MANAGEMENT = 'USER_MANAGEMENT',
  NPS = 'NPS',
  HUMAN_RESOURCES = 'HUMAN_RESOURCES',
  DELEGATION_LIST = 'DELEGATION_LIST',
  BANK_BRANCHES = 'BANK_BRANCHES',
  BIG_AGREEMENTS = 'BIG_AGREEMENTS',
  SMS_LOGS = 'SMS_LOGS',
  EMAIL_LOGS = 'EMAIL_LOGS',
  PROSPECTS_SETTINGS = 'PROSPECTS_SETTINGS',
  MULTIFORM = 'MULTIFORM',
  OLD_COMMISSIONS = 'OLD_COMMISSIONS',
  PRODUCTS = 'PRODUCTS',
  CASH_COMPARISON_TOOL = 'CASH_COMPARISON_TOOL',
  MORTGAGE_COMPARISON_TOOL = 'MORTGAGE_COMPARISON_TOOL',
  AI = 'AI',
}
