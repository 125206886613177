import { render, staticRenderFns } from "./AnonymousBorrowerBadge.vue?vue&type=template&id=6384b052&scoped=true"
import script from "./AnonymousBorrowerBadge.vue?vue&type=script&lang=ts"
export * from "./AnonymousBorrowerBadge.vue?vue&type=script&lang=ts"
import style0 from "./AnonymousBorrowerBadge.vue?vue&type=style&index=0&id=6384b052&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.4.23_babel-core@6.26.3_css-loader@6.11.0_webpack@5.89._kmu2dhqlsy77s2nof2cqzt33jm/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6384b052",
  null
  
)

export default component.exports