import { render, staticRenderFns } from "./DataCardElementRow.vue?vue&type=template&id=2af16cbc&scoped=true"
import script from "./DataCardElementRow.vue?vue&type=script&lang=ts"
export * from "./DataCardElementRow.vue?vue&type=script&lang=ts"
import style0 from "./DataCardElementRow.vue?vue&type=style&index=0&id=2af16cbc&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.4.23_babel-core@6.26.3_css-loader@6.11.0_webpack@5.89._kmu2dhqlsy77s2nof2cqzt33jm/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2af16cbc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/.pnpm/vuetify-loader@1.7.3_vue-template-compiler@2.7.14_vuetify@2.7.1_vue@2.7.14__webpack@5.89.0/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VCol,VRow,VSkeletonLoader})
