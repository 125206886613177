import {ProductType} from '@/commons/enums/ProductType';
import {DealStatus} from '@/modules/deals/enums/DealStatus';
import {DealStage} from '@/modules/deals/enums/DealStage';
import Client, {ClientDTO} from '@/models/Client';
import {Person, PersonName} from '@/models/Person';
import {HistoryElement} from '@/models/HistoryElement';
import Partner, {FinpackSource, Salesman} from '@/models/Partner';
import {Address, Household, HouseholdDto} from '@/modules/multiForm/shared/MultiformModels';
import AuthService from '@/modules/user/AuthService';
import Agent from '@/models/Agent';
import {SettledDocuments} from '@/models/SettledDocuments';
import {SettledDocumentsStatus} from '@/modules/deals/enums/DocumentsStatus';
import {AnonymousBorrower, AnonymousBorrowerDto} from '@/modules/client/models/AnonymousBorrower';
import CheckedDocument from '@/components/checklist/models/CheckedDocument';
import {DealAttributes, RealEstateQuestionnaireAnswers} from '@/models/DealAttributes';
import Activity from '@/models/Activity';
import EnvironmentProducts from '@/env/services/EnvironmentProducts';
import {RealEstate} from '@/models/RealEstate';
import {RealEstateSupportKind} from '@/models/enums/RealEstateSupportKind';
import {Routes} from '@/router/Routes';
import User from '@/models/user/User';
import {DealDetailsSegments} from '@/types/Dictionaries';
import {DealIcons} from '@/commons/dictionaries/DealIcons';
import {Utils} from '@/services/utils/BasicUtils';

export type Attachments = {
  informationForm: Nullable<number[]>,
  agreement: Nullable<number>,
  decision: Nullable<number>,
  subsequentInformationForms: Nullable<number>;
}
export abstract class DealDTO {
  public id!: number;
  public loanAmount?: number;
  public isDelegated?: boolean;
  public name: string = '';
  public propertyValue?: number;
  public agent: Nullable<Agent> = null;
  public delegatedTo?: User;
  public bankMargin?: number;
  public bankCommission?: number;
  public productType: Nullable<ProductType> = null;
  public status: DealStatus = DealStatus.DEAL_CREATED;
  public stage: DealStage = DealStage.PENDING;

  public createdAt!: string;
  public updatedAt!: string;
  public nextMeetingActivity?: Activity;
  public realisationAt?: Nullable<string> = null;
  public submittedAt?: Nullable<string> = null;
  public wonAt?: Nullable<string> = null;

  public clients: Array<ClientDTO> = [];
  public anonymousBorrowers: Array<AnonymousBorrowerDto> = [];
  public clientIds: Array<number> = [];
  public documentTags!: Array<number>;
  public agentId?: number;
  public agentCCId?: number;
  public partnerId?: Nullable<number>;
  public partnerSourceId?: number;
  public partnerSalesmanId?: number;
  public simulationId?: Nullable<string>;
  public partner?: Nullable<Partner> = null;
  public partnerSource?: Nullable<FinpackSource> = null;
  public partnerSalesman?: Salesman;
  public agentCC?: Person;
  public provisions?: any;
  public settledApplicationId?: number;
  public attachments: Attachments = {
    agreement: null,
    decision: null,
    informationForm: [],
    subsequentInformationForms: null,
  };

  public checkedDocuments?: CheckedDocument[];
  public wizardStep?: string;
  public households: Array<HouseholdDto> = [];
  public settledDocuments!: SettledDocuments;
  public verifierId?: Nullable<number> = null;
  public usercomDealId?: Nullable<number> = null;
  public history?: Array<HistoryElement<any>>;
  public realEstateDetails!: Nullable<RealEstate[]>;
  public lastContactWithClientAt: Nullable<string> = null;
  public expectedRealisationAt: Nullable<string> = null;
  public isLookingForMortgage?: boolean;
  public attributes?: DealAttributes;
  public isDocumentsAvailableToClients: boolean = false;
  public isDocumentsSentToDeveloper: boolean = false;
  public developerEmail?: string;
  public documentsNoteForClients?: Nullable<string>;
  public note?: string;
  public questionnaireRealEstate?: Nullable<RealEstateQuestionnaireAnswers>;
  public realEstateQuestionnaireId?: number;
  public bankApplicationNumber?: Nullable<string>;
  public hasActivities?: boolean;
  public hasActivitiesForToday?: boolean;
  public hasLateActivities?: boolean;
  public hasNotes?: boolean;
  public prospectUuid?: string;
  public callCenter?: boolean;
  public clientAgreedCreditDraft: Nullable<boolean> = null;
  public clientAgreedCreditBefore21Days: Nullable<boolean> = null;
  public createClientWithScansAvailableTo?: string;
  public hideSensitiveInformation?: boolean
}

export default class Deal extends DealDTO {
  public clients: Array<Client> = [];
  public agent: Nullable<Agent> = null;
  public anonymousBorrowers: Array<AnonymousBorrower> = [];
  public households: Array<Household> = [];
  public isCreatedByClient?: boolean = false;
  public realEstateDetails: Nullable<RealEstate[]> = null;
  public questionnaireRealEstate: Nullable<RealEstateQuestionnaireAnswers> = null;
  public requireBankSecrecy?: boolean = false;
  public widgetOwnerId?: Nullable<number> = null;
  public widgetOwner?: Nullable<PersonName> = null;
  public activities: Array<Activity> = [];
  public history: Array<HistoryElement<any>> = [];
  public isNoChange: boolean = false;
  public hideSensitiveInformation: boolean = false

  constructor(dealDTO?: Partial<DealDTO>) {
    super();
    if (dealDTO) {
      Utils.assign(this, dealDTO);
      if (dealDTO.agent) {
        this.agent = new Agent(dealDTO.agent);
      }

      if (dealDTO.clients) {
        this.clients = dealDTO.clients.map((client: ClientDTO) => client instanceof Client
          ? client
          : new Client(client));
      }

      if (dealDTO.anonymousBorrowers) {
        this.anonymousBorrowers = dealDTO.anonymousBorrowers.map((borrower: AnonymousBorrowerDto) =>
          borrower instanceof AnonymousBorrower
            ? borrower
            : new AnonymousBorrower(borrower));
      }

      if (dealDTO.households) {
        this.households = dealDTO.households.map(household => household instanceof Household
          ? household
          : new Household(household));
      }

      if (dealDTO.partnerSalesman) {
        this.partnerSalesman = new Salesman(dealDTO.partnerSalesman);
      }

      if (dealDTO.delegatedTo) {
        this.delegatedTo = new User(dealDTO.delegatedTo);
      }

      if (!dealDTO.questionnaireRealEstate && dealDTO.productType === ProductType.REAL_ESTATE) {
        this.questionnaireRealEstate = new RealEstateQuestionnaireAnswers();
      }
      if (dealDTO.partner) {
        this.partner = new Partner(dealDTO.partner);
      }
      if (dealDTO.partnerSource) {
        this.partnerSource = dealDTO.partnerSource;
      }
    }
  }

  public get isCreditDeal(): boolean {
    return !!this.productType && [
      ProductType.CASH,
      ProductType.MORTGAGE,
      ProductType.SME,
      ProductType.NONBANK,
    ].includes(this.productType);
  }

  public get allClients(): (Client | AnonymousBorrower)[] {
    return [...this.clients, ...this.anonymousBorrowers,];
  }

  get expectedRealisationMonth(): Nullable<string> {
    if (this.expectedRealisationAt) {
      const date = (new Date(this.expectedRealisationAt)).toISOString();
      return date.substr(0,7);
    } else return null;
  }

  set expectedRealisationMonth(newValue: Nullable<string>) {
    if (newValue) {
      if (newValue.length === 7) {
        const year = Number(newValue.substring(0, 4));
        const month = Number(newValue.substring(5, 7)) - 1;
        this.expectedRealisationAt = (new Date(year, month, 2)).toISOString();
      }
    } else {
      this.expectedRealisationAt = null;
    }
  }

  public get dealWizardStep(): string {
    return this.partner?.requireReporting && !this.wizardStep
      ? Routes.DEALS.WIZARD.BUILD_TRUST_SECTION
      : this.wizardStep && this.wizardStep !== 'undefined' ? this.wizardStep : Routes.DEALS.WIZARD.CHOOSE_OFFERS;
  }

  public get stageColor(): string {
    const chipColors = {
      [DealStage.PENDING]: '',
      [DealStage.WON]: 'success',
      [DealStage.LOST]: 'error',
    };
    return chipColors[this.stage];
  }

  public get isEditableByClients(): boolean {
    return this.clients.map(client => client.isEditableByClient).every(x => x);
  }

  get creditColor(): string {
    return EnvironmentProducts().find(x => x.type === this.productType!)!.color;
  }

  get typeLabel(): string {
    return EnvironmentProducts().find(x => x.type === this.productType!)!.nominative?.female || '';
  }

  public openDealForEdit(): void {
    this.clients.forEach(client => {
      client.isEditableByClient = true;
    });
  }

  public get myReward(): number | null {
    const provisionObject = this.provisions && AuthService.User
      ? this.provisions.provisions.find((x: any) => x.userId === AuthService.User!.id)
      : null;
    return provisionObject ? provisionObject.provision : null;
  }

  public get agentReward(): number | null {
    const provisionObject = this.provisions && AuthService.User && AuthService.User.id !== this.agentId
      ? this.provisions.provisions.find((x: any) => x.userId !== AuthService.User!.id)
      : null;
    return provisionObject ? provisionObject.provision : null;
  }

  public get daysToWon(): number | undefined {
    return this.wonAt && this.wonAtObject && this.createdAt
      ? Math.ceil(Math.abs(this.wonAtObject.getTime() - this.createdAtDateObject.getTime()) / (1000 * 3600 * 24))
      : undefined;
  }

  public get isDalegatee(): boolean {
    return this.delegatedTo !== undefined && AuthService.User!.id === this.delegatedTo!.id;
  }

  public get isAccessBlocked(): boolean {
    if (AuthService.User?.isAdminOrVerifier ||
      AuthService.User?.privileges?.isAgentCC ||
      (!this.isDelegated && !this.delegatedTo) ||
      this.isDalegatee) {
      return false;
    } else if (AuthService.User?.isChiefOfDivision) {
      const delegatedToOwnDivision = this.delegatedTo?.divisions?.some(delegatedDivision => {
        return AuthService.User?.divisionsChief?.some(division => division.id === delegatedDivision);
      });
      return !delegatedToOwnDivision;
    } else {
      return true;
    }
  }

  public get isPending(): boolean {
    return this.stage === DealStage.PENDING;
  }

  public get isWon(): boolean {
    return this.stage === DealStage.WON;
  }

  public get isLost(): boolean {
    return this.stage === DealStage.LOST;
  }

  public get isInVerification(): boolean {
    return this.status === DealStatus.SENT_TO_VERIFICATION || this.status === DealStatus.STARTED_VERIFICATION;
  }

  public get icon() {
    return this.productType ? DealIcons[this.productType] : require('@/assets/bankLogotypes/PLACEHOLDER-circle.webp');
  }

  public get simulationPrefix(): Nullable<string> {
    const prefixes = {
      [ProductType.CASH]: 'cash-backend',
      [ProductType.SME]: 'sme-backend',
      [ProductType.MORTGAGE]: 'backend',
      [ProductType.NONBANK]: 'cash-backend',
      [ProductType.REAL_ESTATE]: null,
      [ProductType.INSURANCE]: null,
      [ProductType.LEASING]: null,
      [ProductType.INVESTMENT]: null,
      [ProductType.FACTORING]: null,
    };
    return prefixes[this.productType!] || null;
  }

  public get mainClient(): Client | null {
    return this.clients[0] || null;
  }

  public set mainClient(c: Client | null) {
    if (this.clients && c) {
      this.clients.shift();
      this.clients.unshift(c);
    }
  }

  public get wonAtObject(): Date | undefined {
    return this.wonAt ? new Date(this.wonAt) : undefined;
  }

  public get createdAtDateObject(): Date {
    return new Date(this.createdAt);
  }

  public set createdAtDateObject(newDate: Date) {
    this.createdAt = newDate.toISOString();
  }

  public get lastContactWithClientAtDateObject(): Nullable<Date> {
    return this.lastContactWithClientAt ? new Date(this.lastContactWithClientAt) : null;
  }

  public set lastContactWithClientAtDateObject(newDate: Nullable<Date>) {
    this.lastContactWithClientAt = newDate ? newDate.toISOString() : null;
  }

  public get submittedAtDateObject(): Nullable<Date> {
    return this.submittedAt ? new Date(this.submittedAt) : null;
  }

  public set submittedAtDateObject(newDate: Nullable<Date>) {
    this.submittedAt = newDate ? newDate.toISOString() : null;
  }

  public get realisationAtDateObject(): Nullable<Date> {
    return this.realisationAt ? new Date(this.realisationAt) : null;
  }

  public set realisationAtDateObject(newDate: Nullable<Date>) {
    this.realisationAt = newDate ? newDate.toISOString() : null;
  }

  public get isCash() {
    return this.productType === ProductType.CASH;
  }

  public get isMortgage() {
    return this.productType === ProductType.MORTGAGE;
  }

  public get isSme() {
    return this.productType === ProductType.SME;
  }

  public get isRealEstate() {
    return this.productType === ProductType.REAL_ESTATE;
  }

  get isRealEstateSelling(): boolean {
    return !!this.questionnaireRealEstate?.supportKind.includes(RealEstateSupportKind.SELL_REAL_ESTATE);
  }

  get isRealEstateBuying(): boolean {
    return !!this.questionnaireRealEstate?.supportKind.includes(RealEstateSupportKind.BUY_REAL_ESTATE);
  }

  public get allClientsHaveProcessingAgreement(): boolean {
    return this.clients
      .every(client => client.agreements.acceptsProcessing);
  }

  public get allClientsHaveInfoAgreement(): boolean {
    return this.clients
      .every(client => client.agreements.acceptsReceivingInfo);
  }

  public get allClientsHavePartnerProcessingAgreement(): boolean {
    return this.clients
      .every(client => client.agreements.acceptsProcessingPartner);
  }

  public get allClientsHavePartnerInfoAgreement(): boolean {
    return this.clients
      .every(client => client.agreements.acceptsReceivingInfoPartner);
  }


  private getSettledDocumentStatus(document: keyof SettledDocuments): SettledDocumentsStatus {
    return this.settledDocuments[document]?.isUploaded
      ? SettledDocumentsStatus.UPLOADED : this.settledDocuments[document]?.isRequired
        ? SettledDocumentsStatus.REQUIRED : SettledDocumentsStatus.NOT_REQUIRED;
  }

  public get settleDocumentAgreement(): SettledDocumentsStatus {
    return this.getSettledDocumentStatus('agreement');
  }

  public get settleDocumentDecision(): SettledDocumentsStatus {
    return this.getSettledDocumentStatus('decision');
  }

  public get settleDocumentInformationForm(): SettledDocumentsStatus {
    return this.getSettledDocumentStatus('informationForm');
  }

  public get settleDocumentSubsequentInformationForms(): SettledDocumentsStatus {
    return this.getSettledDocumentStatus('subsequentInformationForm');
  }

  public get allAddresses(): Address[] {
    return this.clients
      .flatMap(client => [client.primaryAddress!, client.mainAddress!, client.secondaryAddress!,])
      .filter(address => address !== null && address?.isFilled);
  }

  changeAnonymousBorrowerIntoClient(borrowerId: number, newClient: Client) {
    this.anonymousBorrowers.splice(this.anonymousBorrowers.findIndex(x => x.id === borrowerId), 1);
    this.households.forEach((household, hhIndex) => {
      if (household.anonymousBorrowersIds.includes(borrowerId)) {
        const indexToRemove = this.households[hhIndex].anonymousBorrowersIds.indexOf(borrowerId);
        this.households[hhIndex].anonymousBorrowersIds.splice(indexToRemove, 1);
        this.addClientToHousehold(newClient, this.households[hhIndex].id!);
      }
    });
  }

  addClientToHousehold(newClient: Client, householdId: number): void {
    this.clients.push(newClient);
    this.clientIds.push(newClient.id!);
    const clientHouseholdId = this.households.findIndex(x => x.id === householdId);
    this.households[clientHouseholdId].clientsIds.push(newClient.id);
    if (this.clients.length === 1 && this.activities.filter(x => x.clientId).length === 0) {
      this.activities.forEach(activity => {
        activity.clientId = newClient.id;
        activity.client = newClient;
      });
    }
  }

  removeAnonymousBorrowerFromHousehold(anonymousBorrower: AnonymousBorrower) {
    const index = this.anonymousBorrowers.findIndex(x => x.id === anonymousBorrower.id);
    if (index > -1) {
      this.anonymousBorrowers.splice(index, 1);
    } else {
      console.error('Cannot find index of anonymous borrower inside deal: ', index);
    }

    const hhIndex = this
      .households
      .findIndex(el => el.anonymousBorrowersIds.includes(anonymousBorrower.id!))!;

    if (hhIndex > -1) {
      const borrowerHhIndex = this
        .households[hhIndex]
        .anonymousBorrowersIds
        .findIndex(cId => cId === anonymousBorrower.id);

      if (borrowerHhIndex > -1) {
        this.households[hhIndex].anonymousBorrowersIds.splice(borrowerHhIndex, 1);
      } else {
        console.error('Cannot find index of anonymous borrower inside household: ', borrowerHhIndex);
      }
    } else {
      console.error('Cannot find index of household inside deal: ', hhIndex);
    }
  }

  get canChangeIsLookingForMortgage(): boolean {
    return this.isMortgage && [
      DealStatus.DEAL_CREATED,
      DealStatus.COMMUNICATION_ARRANGED,
      DealStatus.COMMUNICATION_PERFORMED,
      DealStatus.ABLE_TO_START_PROCESS,
      DealStatus.SIMULATION_PERFORMED,
      DealStatus.APPOINTMENT_ARRANGED,
      DealStatus.APPOINTMENT_PERFORMED,
    ].includes(this.status);
  }

  get isDealRequiredReporting(): boolean {
    return !!this.partner?.requireReporting;
  }

  get isUserOwner(): boolean {
    return !!AuthService.User &&
      (AuthService.User.isAdmin ||
        AuthService.User.isVerifier ||
        AuthService.User.isChiefOfDivision ||
        this.agentId === AuthService.User.id ||
        this.delegatedTo?.id === AuthService.User.id);
  }

  get isUserDelegatee(): boolean {
    return this.isUserOwner &&
      !!this.delegatedTo?.id && this.delegatedTo?.id !== AuthService.User!.id;
  }

  get userHaveSimplePartnerView(): boolean {
    return AuthService.User!.privileges.simplePartnerView &&
      (!this.isUserOwner || !!this.delegatedTo?.id);
  }

  get userCanEdit(): Record<DealDetailsSegments, boolean> {
    return {
      [DealDetailsSegments.PREVIEW]: true,
      [DealDetailsSegments.PROCESS]: this.isUserOwner,
      [DealDetailsSegments.DOCS]: this.isUserOwner || this.isUserDelegatee,
      [DealDetailsSegments.NOTES]: true,
      [DealDetailsSegments.REPORT]: this.isWon && AuthService.User!.isReportDepartment,
      [DealDetailsSegments.EKO]: this.isUserOwner,
      [DealDetailsSegments.ACTIVITY]: this.isUserOwner && !this.isLost,
      [DealDetailsSegments.DEAL_DATA]: this.isUserOwner,
      [DealDetailsSegments.STATUS]: !this.isLost,
      [DealDetailsSegments.CLIENTS]: !this.isLost,
      [DealDetailsSegments.LOAN_APPLICATIONS]: !this.isLost,
      [DealDetailsSegments.MULTIFORM]: !this.isLost &&
      !!this.mainClient &&
      !AuthService.User?.privileges.simplePartnerView,
      [DealDetailsSegments.DISCOUNTS]: true,
    };
  }

  get userCanDisplay(): Record<DealDetailsSegments, boolean> {
    return {
      [DealDetailsSegments.PREVIEW]: true,
      [DealDetailsSegments.PROCESS]: this.isUserOwner && !AuthService.User!.privileges.simplePartnerView,
      [DealDetailsSegments.DOCS]: this.isUserOwner,
      [DealDetailsSegments.NOTES]: true,
      [DealDetailsSegments.REPORT]: this.isWon && AuthService.User!.isReportDepartment,
      [DealDetailsSegments.EKO]: true,
      [DealDetailsSegments.ACTIVITY]: this.isUserOwner && !AuthService.User!.privileges.simplePartnerView,
      [DealDetailsSegments.DEAL_DATA]: true,
      [DealDetailsSegments.STATUS]: !!this.mainClient,
      [DealDetailsSegments.LOAN_APPLICATIONS]: true,
      [DealDetailsSegments.MULTIFORM]: true,
      [DealDetailsSegments.CLIENTS]: true,
      [DealDetailsSegments.DISCOUNTS]: true,
    };
  }

  get isFinanceDeal(): boolean {
    return this.productType
      ? [ProductType.MORTGAGE, ProductType.CASH, ProductType.SME,].includes(this.productType)
      : false;
  }
}
export interface KanbanDealsItem {
  status: {
    type: DealStatus | null,
    name: string | null,
  };
  quantity: number | null,
  loanAmountSum: number | null,
  tasks: Deal[];
}
